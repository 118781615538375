<template>
  <v-col cols="12" sm="6" md="3" class="col">
    <section class="pa-2 item-card">
      <img
        src="../../../assets/images/cards/land-card-bg.png"
        class="item-card-background"
      />
      <v-img
        :src="item.record_detail.art_portrait"
        class="item-card-image"
        contain
      ></v-img>
      <v-divider class="my-2"></v-divider>
      <div class="mt-1">
        <div class="d-flex flex-column">
          <h5 class="item-card-name">
            {{ item.record_detail.name.substring(0, 38) }}
          </h5>
          <h5 class="item-card-size">
            <span class="">{{ item.record_detail.size }}</span>
          </h5>
          <h5 class="item-card-level">
            <span class="">#0000000</span>
          </h5>
          <h5 class="item-card-price">
            <span> {{ item.price.usdt.price }}$ </span>
          </h5>
          <h5 class="item-card-id">
            <span>{{ item.id }}</span>
          </h5>
        </div>
      </div>
      <button class="cancle-button" style="" @click.stop="cancel(item)">
        <span>Cancel</span>
      </button>
      <!-- <button
        class="shop-button darken-4 black--text"
        @click.stop="cancel(item)"
      >
        Cancel
      </button> -->
    </section>
  </v-col>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    cancel(item) {
      this.$emit("cancel", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  
  .item-card {
    position: relative;
    // background: red;
    .item-card-background {
      width: 100%;
      cursor: pointer;
      position: relative;
      .skeleton {
        position: absolute;
        top: 0;
        bottom: 0;
      }
    }
    .item-card-image {
      position: absolute;
      top: 35.5%;
      left: 11.5%;
      right: 11.5%;
      pointer-events: none;
      border-radius: 5px;
      transform: scaleY(1.06) translateY(9px);
      .skeleton {
        position: absolute;
        top: 0;
        bottom: 0;
      }
    }
    .cancle-button {
      position: absolute;
      width: 40%;
      background: red;
      height: 15%;
      background: url("../../../assets/images/buttons/buy-button-background.png")
        no-repeat !important;
      background-size: contain !important;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
      &:hover {
        animation: shake 0.4s linear 1;
      }
      span {
        font-size: 10px;
        font-weight: 600;
        color: #ffd600;
        text-shadow: 0px 0px 4px #ffd600;
      }
    }
    
    .item-card-name {
      position: absolute;
      top: 23%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 10px;
      font-weight: 9000;
      color: #000e44;
      text-align: center;
      line-height: 12px;
    }
    .item-card-size {
      position: absolute;
      top: 29%;
      right: 25%;
      span {
        color: white;
        font-size: 9px;
      }
    }
    .item-card-level {
      position: absolute;
      top: 29%;
      left: 25%;
      span {
        color: white;
        font-size: 9px;
      }
    }
    .item-card-price {
      position: absolute;
      bottom: 15%;
      left: 15%;
      span {
        color: #000e44;
        font-size: 12px;
      }
    }
    .item-card-id {
      position: absolute;
      bottom: 15%;
      right: 14%;
    }
    
  }
  @keyframes shake {
  0% {
    transform: translateX(-47%);
  }
  25% {
    transform: translateX(-50%);
  }
  50% {
    transform: translateX(-47%);
  }
  75% {
    transform: translateX(-53%);
  }
  100% {
    transform: translateX(-47%);
  }
}
}
</style>
