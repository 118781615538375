<template>
  <section class="unchain-nft">
    <section class="content">
      <section v-if="loading">
        <div class="no-item">
          <loading-spinner></loading-spinner>
        </div>
      </section>
      <section v-else>
        <div class="no-item" v-if="listOfNFtUrls.length <= 0">
          <h1 class="white--text">Nothing To show !</h1>
          <p class="orange--text" v-if="connectedTo !== 'METAMASK'">
            <v-icon class="mb-1" color="orange" size="18">mdi-alert</v-icon> For
            view your Nfts should connect to metamask wallet First.
          </p>
        </div>

        <v-row class="row" v-else>
          <NftItem
            v-for="(item, i) in listOfNFtUrls"
            :key="i"
            :item="item"
          ></NftItem>
        </v-row>
      </section>
      <!-- <v-divider class="white mt-2"></v-divider>
      <div class="table-paginate-box">
        <div class="table-pagination-total">
          {{ total }} <span class="">result(s)</span>
        </div>
        <div class="paginate-box">
          <v-pagination
            v-model="page"
            class="pagination"
            :length="lastPage"
            total-visible="5"
            @input="changePage()"
            color="blue"
            circle
          ></v-pagination>
        </div>
      </div> -->
    </section>
    
  </section>
</template>

<script>
import { callContractMixin } from "../../../mixins/callContractMixin.js";
import { mapActions, mapGetters } from "vuex";
// import Moralis from "moralis";
import axios from "axios";
import NftItem from "./NftItem.vue";
export default {
  mixins: [callContractMixin],
  data() {
    return {
      contractAddress: "0x989EB7602Eb7Ef5C4831C7e8B6Eae34831995E90",
      loading: false,
      listOfNFtUrls:[],


      //for polygon nfts with moralis and pagination
      // polygonNFTS: [],
      // polygonNFTSForThisPage: [],
      // goalNfts: [],

      //paginations
      // total: 0,
      // page: 1,
      // lastPage: 0,
      // startIndex: 0,
      // endIndex: 8,

    };
  },
   components: {
    NftItem,
  },
  computed: {
    ...mapGetters(["wallet_address", "connectedTo"]),
  },
  created() {
    if (this.connectedTo === "METAMASK") this.getListOfBalance();
  },
  methods: {
    ...mapActions(["depositNft"]),
     async getListOfBalance() {
      this.loading = true;
      await this.callContract(
        this.contractAddress, //contract address
        "balanceOf",
        [this.wallet_address],
        async (res) => {
          console.log({res});
          let balanceCount = parseInt(res);
          if (balanceCount <= 0) {
            this.loading = false;
          } else {
            await this.getDetailById(balanceCount);
          }
        },
        false //read false & write true
      );
    },
    async getDetailById(index) {
      const helperArray = [];
      for (let i = 0; i < index; i++) {
        const element = i;
        helperArray.push(element);
      }
      console.log({helperArray});
      helperArray.forEach(async (element) => {
        await this.callContract(
          this.contractAddress,
          "tokenOfOwnerByIndex",
          [this.wallet_address, element],
          (res) => {
            let id = parseInt(res);
            this.getTokenUrlById(id);
          },
          false //read false & write true
        );
      });
    },
    async getTokenUrlById(id) {
      this.listOfNFtUrls = [];
      await this.callContract(
        this.contractAddress,
        "tokenURI",
        [id],
        async (res) => {
          let tokenUrl = res;
          console.log(tokenUrl);
          if (tokenUrl) {
            axios.get(tokenUrl).then((res) => {
              console.log(res.data);
              this.listOfNFtUrls.push({ ...res.data, nft_id: id });
              this.loading = false;
            });
          }
        },
        false //read false & write true
      );
    },


      //approche with moralis and fucking pagination

    // async getNFTs() {
    //   const serverUrl = "https://tps5x5defrri.usemoralis.com:2053/server";
    //   const appId = "c99iNEMJfpms2GBle4yIQxEvm6Q32ssHA2K8jsW9";
    //   Moralis.start({ serverUrl, appId });
    //   const options = {
    //     chain: "MATIC",
    //     address: "0xa34f511dF58f835cf0Ede77C583632F466057C28",
    //     // address: this.wallet_address,
    //   };
    //   this.polygonNFTS = await Moralis.Web3.getNFTs(options);
    //   console.log(this.polygonNFTS);
    //   this.total = this.polygonNFTS.length;
    //   this.lastPage = Math.ceil(this.polygonNFTS.length / 8);
    //   this.changePage();
    // },
    // changePage() {
    //   console.log(this.page);
    //   this.startIndex = (this.page - 1) * 8;
    //   this.endIndex = this.startIndex + 8;
    //   console.log(this.startIndex, this.endIndex);
    //   this.polygonNFTSForThisPage = this.polygonNFTS.slice(
    //     this.startIndex,
    //     this.endIndex
    //   );
    //   this.loading = true;
    //   this.getUriData(this.polygonNFTSForThisPage);
    //   setTimeout(() => {
    //     this.loading = false;
    //   }, 3500);
    // },
    // getUriData(array) {
    //   this.goalNfts = [];
    //   this.loading = true;
    //   array.forEach((element) => {
    //     if (element.token_uri) {
    //       axios.get(element.token_uri).then((res) => {
    //         this.goalNfts.push({ ...res.data, token_id: element.token_id });
    //       });
    //     }
    //   });
    // },

  },
};
</script>

<style lang="scss" scoped>
.content {
  .no-item {
    width: 100%;
    min-height: 40vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
}
</style>
