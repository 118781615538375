<template>
  <section class="for-sales">
    <section class="content">
      <section v-if="isLoading">
        <div class="no-item">
          <loading-spinner></loading-spinner>
        </div>
      </section>
      <section v-else>
        <div class="no-item" v-if="itemsForSale.length <= 0">
          <h1 class="white--text">Nothing To show !</h1>
        </div>
        <v-row class="row" v-else>
          <SaleItem v-for="(item, i) in itemsForSale" :key="i" :item="item" @cancel="cancel"></SaleItem>
        </v-row>
      </section>
    </section>

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      color="green accent-4 "
      dense
      centerd
      shaped
      top
      transition="slide-y-transition"
      style="position: fixed"
    >
      <div class="d-flex">
        {{ message }}
        <v-spacer></v-spacer>
        <v-icon @click="snackbar = false">mdi-close</v-icon>
      </div>
    </v-snackbar>
    <!-- snackbar -->
  </section>
</template>

<script>
import SaleItem from './SaleItem.vue'
import { mapActions } from "vuex";
export default {
  data() {
    return {
      itemsForSale: [],
      isLoading: false,
      snackbar: false,
      message: "",
    };
  },
  components:{
    SaleItem
  },
  created() {
    this.fetchUserForSales();
  },
  methods: {
    ...mapActions(["getUserForSales", "cancelSell"]),
    fetchUserForSales() {
      this.isLoading = true;
      this.getUserForSales()
        .then((result) => {
          console.log(result);
          this.itemsForSale = result.data.data;
          // result.data.data.forEach(element => {
          //     this.helperArray.push(element.record_detail);
          //     this.itemsForSale = this.helperArray;

          //   });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    cancel(item) {
      this.cancelSell(item.id).then((res) => {
        this.snackbar = true;
        this.message = res.data.message;
        this.fetchUserForSales();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.for-sales {
  .content {
    .row {
      .shop-button {
        background: rgb(132, 0, 0);
      }
    }
  }
}
</style>
