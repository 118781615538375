<template>
  <v-col cols="12" sm="6" md="3" class="">
    <section class="pa-2 item-card">
      <img
        src="../../../assets/images/cards/land-card-bg.png"
        class="item-card-background"
      />
      <!-- <img :src="item.image" style="width: 100%; min-height: 160px" />
      <v-divider class="my-2"></v-divider> -->
      <v-img :src="item.image" class="item-card-image" contain></v-img>
      <div class="mt-1">
        <div class="d-flex flex-column">
          <h5 class="item-card-name">
            {{ item.name.substring(0, 38) }}
          </h5>
          <h5 class="item-card-size">
            <span class="">{{ item.attributes.size }}</span>
          </h5>
          <h5 class="item-card-level">
            <span class="">{{ item.attributes.category }}</span>
          </h5>
          <h5 class="item-card-price">
            <span> {{ item.attributes.landscape }} </span>
          </h5>
          <h5 class="item-card-id">
            <span>{{ item.nft_id }}</span>
          </h5>
        </div>
      </div>
      <button
        class="unchain-button"
        
        @click.stop="unchainNft()"
        :loading="approveLoading"
      >
        <span>Unchain NFT</span>
      </button>
      <!-- <div class="mt-1">
        <div class="d-flex flex-column">
          <h6 class="d-flex">
            <p class="green--text">{{ item.name }}</p>
          </h6>
          <div class="d-flex justify-space-between">
            <h1 class="d-flex">
              <p class="grey--text mr-1">Category:</p>
              <p class="orange--text">{{ item.attributes.category }}</p>
            </h1>
            <h1 class="d-flex">
              <p class="grey--text mr-1">Id:</p>
              <p class="orange--text">{{ item.nft_id }}</p>
            </h1>
          </div>
          <div class="d-flex justify-space-between">
            <h1 class="d-flex">
              <p class="grey--text mr-1">Landscape:</p>
              <p class="orange--text">{{ item.attributes.landscape }}</p>
            </h1>
            <h1 class="d-flex">
              <p class="grey--text mr-1">size:</p>
              <p class="orange--text">{{ item.attributes.size }}</p>
            </h1>
          </div>
        </div>
      </div> -->
      <!-- <v-btn
        class="shop-button mt-3"
        @click.stop="unchainNft()"
        :loading="approveLoading"
      >
        Unchain NFT
      </v-btn> -->
    </section>
    <!--quastion dialog -->
    <v-dialog v-model="quastionDialog" width="500">
      <QuastionCard
        @cancel="quastionDialog = false"
        text="Are you sure to unchain this item ? "
        :isLoading="false"
        @accept="startAccept"
      ></QuastionCard>
    </v-dialog>
    <!--quastion dialog -->
  </v-col>
</template>

<script>
import { mapActions } from "vuex";
import { callContractMixin } from "../../../mixins/callContractMixin.js";
export default {
  mixins: [callContractMixin],
  props: ["item"],
  data() {
    return {
      quastionDialog: false,
      interval: null,
      approveLoading: false,
      contractAddress: "0x989EB7602Eb7Ef5C4831C7e8B6Eae34831995E90",
    };
  },
  beforeDestroy() {
    this.stopInterval();
  },
  methods: {
    ...mapActions(["setAlert"]),
    //deposit
    unchainNft() {
      this.quastionDialog = true;
    },
    async accept(approve) {
      this.quastionDialog = false;
      const info = {
        nft_id: this.item.nft_id,
      };
      console.log(info);
      //getApproved call contract
      await this.callContract(
        this.contractAddress,
        "getApproved",
        [info.nft_id],
        async (res) => {
          console.log({ res });
          if (res === "0xF554cccf790B22FB3a49186300A0CF3e15002466") {
            this.stopInterval();
            this.setAlert({
              message: "your front deposit done lets call api from backend",
              type: "success",
            });
          } else {
            //if nft not approved to deposit should open the metamask and approve for that we call approve write call contract
            if (approve) {
              await this.callContract(
                this.contractAddress,
                "approve",
                ["0xF554cccf790B22FB3a49186300A0CF3e15002466", info.nft_id],
                (res) => {
                  console.log({ res });
                  if (res) {
                    if (res.code === 4001) {
                      //rejected
                      this.setAlert({
                        message: res.message,
                        type: "error",
                      });
                    } else {
                      //all done
                      this.setAlert({
                        message:
                          "NFT Approving process started. since it was done you can DEPOSIT It.",
                        type: "success",
                      });
                      this.startIntreval();
                    }
                  } else {
                    //res is false
                    console.log("res is false");
                    this.stopInterval();
                  }
                },
                true
              );
            }
          }
        },
        false
      );
    },
    startAccept() {
      this.accept(true);
    },
    startIntreval() {
      this.approveLoading = true;
      this.interval = setInterval(() => {
        this.accept(false);
      }, 10000);
      setTimeout(() => {
        this.stopInterval();
      }, 300000);
    },

    stopInterval() {
      this.approveLoading = false;
      clearInterval(this.interval);
    },
  },
};
</script>

<style lang="scss" scoped>
.item-card {
  position: relative;
  // background: red;
  .item-card-background {
    width: 100%;
    cursor: pointer;
    position: relative;
    .skeleton {
      position: absolute;
      top: 0;
      bottom: 0;
    }
  }
  .item-card-image {
    position: absolute;
    top: 40%;
    left: 11.5%;
    right: 11.5%;
    pointer-events: none;
    border-radius: 5px;
    transform: scaleY(1.05) ;
    .skeleton {
      position: absolute;
      top: 0;
      bottom: 0;
    }
  }
  .unchain-button {
    position: absolute;
    width: 40%;
    background: red;
    height: 15%;
    background: url("../../../assets/images/buttons/buy-button-background.png")
      no-repeat !important;
    background-size: contain !important;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    &:hover {
      animation: shake 0.4s linear 1;
    }
    span {
      font-size: 10px;
      font-weight: 600;
      color: #ffd600;
      text-shadow: 0px 0px 4px #ffd600;
    }
  }

  .item-card-name {
    position: absolute;
    top: 23%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px;
    font-weight: 9000;
    color: #000e44;
    text-align: center;
    line-height: 12px;
  }
  .item-card-size {
    position: absolute;
    top: 29%;
    right: 25%;
    span {
      color: white;
      font-size: 9px;
    }
  }
  .item-card-level {
    position: absolute;
    top: 29%;
    left: 25%;
    span {
      color: white;
      font-size: 9px;
    }
  }
  .item-card-price {
    position: absolute;
    bottom: 12%;
    left: 15%;
    span {
      color: #000e44;
      font-size: 12px;
    }
  }
  .item-card-id {
    position: absolute;
    bottom: 12%;
    right: 14%;
  }
}
@keyframes shake {
  0% {
    transform: translateX(-47%);
  }
  25% {
    transform: translateX(-50%);
  }
  50% {
    transform: translateX(-47%);
  }
  75% {
    transform: translateX(-53%);
  }
  100% {
    transform: translateX(-47%);
  }
}
</style>
