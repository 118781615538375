<template>
  <section>
    <div class="tabs mb-3">
      <ul class="d-flex pa-0">
        <li
          v-for="(item, i) in tabs"
          :key="i"
          @click="changeTabs(item)"
          :class="item.isActive ? 'active' : ''"
        >
          <span v-if="item.isActive">{{ item.name }}</span>
          <i :class="`fa-solid ${item.icon}`" class="tab-icon" v-else></i>
        </li>
      </ul>
    </div>
    <UserLands v-if="tabs[0].isActive" />
    <UnchainNft v-if="tabs[1].isActive" />
  </section>
</template>

<script>
import UserLands from "@/components/assets/lands/UserLands.vue";
import UnchainNft from "@/components/assets/nfts/UnchainNft.vue";
export default {
  data() {
    return {
      tabs: [
        {
          name: "Lands",
          icon:"fa-map-location-dot",
          isActive: true,
        },
        {
          name: "NFTs",
          icon:"fa-cubes",
          isActive: false,
        },
        {
          name: "Buildings",
          icon:"fa-building",
          isActive: false,
        },
        {
          name: "Lootboxes",
          icon:"fa-gift",
          isActive: false,
        },
        {
          name: "Packs",
          icon:"fa-box",
          isActive: false,
          
        },
      ],
      previousTab: {},
    };
  },
  components: {
    UserLands,
    UnchainNft,
  },
  created() {
    //get session tab name and find it and change tab to that\
    if (sessionStorage.getItem("assets-tab")) {
      this.previousTab = this.tabs.find(
        (element) => element.name === sessionStorage.getItem("assets-tab")
      );
      this.changeTabs(this.previousTab);
    }
  },
  methods: {
    changeTabs(item) {
      //change tab from old to new and save in session
      let oldTab = this.tabs.find((element) => element.isActive === true);
      oldTab.isActive = !oldTab.isActive;
      let newTab = this.tabs.find((element) => element.name === item.name);
      newTab.isActive = !newTab.isActive;
      this.handleSaveTab();
    },
    handleSaveTab() {
      //save active tab(object) name to session
      this.previousTab = this.tabs.find((element) => element.isActive === true);
      sessionStorage.setItem("assets-tab", this.previousTab.name);
    },
  },
};
</script>

<style lang="scss" scoped>
// tabs
.tabs {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  ul {
    border: 3px solid var(--secondary-color) !important;
    padding: 5px 8px !important;
    border-radius: 8px;
    li {
      list-style: none;
      font-weight: 500;
      padding: 7px 3px;
      margin: 0 10px;
      color: var(--secondary-color);
      cursor: pointer;
      width: 10%;
      min-width: 100px;
      text-align: center;
      transition: all 0.3s ease;
      user-select: none;
      border: 1px solid transparent;
        border-radius: 5px;
      &:hover{
        border: 1px solid var(--secondary-color);
      }
      &.active {
        border: 1px solid transparent;
        color: var(--base-color);
        background: var(--secondary-color);
        font-weight: bolder !important;
      }
      @media only screen and(max-width: 600px) {
        padding: 3px 0;
        margin: 0;
        min-width: 60px;
        font-size: 10px;
      }
      .tab-icon{
        font-size: 21px;
      }
    }
  }
}
// tabs
</style>
