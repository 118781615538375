import Web3 from "web3";

const web3 = new Web3(window.ethereum);
import { mapGetters } from "vuex";
import ABI from "./contractABI.json";

export const callContractMixin = {
  computed: {
    ...mapGetters(["wallet_address"]),
  },
  methods: {
    async callContract(
      contractAddress,
      methodName,
      args,
      callback,
      changeState = false
    ) {
      try {
        let myContract = new web3.eth.Contract(ABI, contractAddress);

        const options = {
          from: this.wallet_address,
          gas: 300000,
          gasPrice: await web3.eth.getGasPrice(),
        };

        if (!changeState) {
          await myContract.methods[methodName](...args).call((err, res) => {
            if (res) {
              callback && callback(res);
            }
             else {
              callback && callback(false);
              console.log(err);
            }
          });
        } else {
          await myContract.methods[methodName](...args).send(
            options,
            (err, res) => {
              if (res) {
                callback && callback(res);
              }
              else{
                callback && callback(false);
                console.log(err);
              }
            }
            );
          }
        } catch (error) {
          callback && callback(false);
          console.log(error);
      }
    },

    async getMaticBalance(walletAddress) {
      let result = await web3.eth.getBalance(walletAddress);
      result = web3.utils.fromWei(result, "ether");

      return result;
    },
    // redirectWithDelay() {
    //     if (this.$route.name !== "page-payment") {
    //         this.$router.push("/register");
    //     }
    // },
  },
};
