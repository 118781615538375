<template>
  <section class="assets routes">
    <section class="content">
      <div class="base-tabs">
        <ul class="d-flex">
          <li
            v-for="(item, i) in baseTabs"
            :key="i"
            @click="changeTabs(item)"
            class="white--text"
            :class="item.isActive ? 'active' : ''"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="line"></div>
      <IndexAssets v-if="baseTabs[0].isActive" />
      <ForSales v-if="baseTabs[1].isActive" />
    </section>
  </section>
</template>

<script>
import IndexAssets from "../../components/assets/IndexAssets.vue";
import ForSales from "../../components/assets/forSale/ForSales.vue";
export default {
  data() {
    return {
      baseTabs: [
        {
          name: "Assets",
          isActive: true,
        },
        {
          name: "For Sales",
          isActive: false,
        },
      ],
    };
  },
  components: {
    IndexAssets,
    ForSales,
  },
  methods: {
    changeTabs(item) {
      //change tab from old to new and save in session
      let oldTab = this.baseTabs.find((element) => element.isActive === true);
      oldTab.isActive = !oldTab.isActive;
      let newTab = this.baseTabs.find((element) => element.name === item.name);
      newTab.isActive = !newTab.isActive;
    },
  },
};
</script>

<style lang="scss" scoped>
.assets {
  .content {
    border-radius: 5px;
    width: 98%;
    margin: 0 auto;
    
  }
}
</style>
