<template>
  <section class="land">
    <section class="content">
      <section v-if="isLoading">
        <div class="no-item">
          <loading-spinner></loading-spinner>
        </div>
      </section>
      <section v-else>
        <div class="no-item" v-if="userLands.length <= 0">
          <h1 class="white--text">Nothing To show !</h1>
        </div>
        <v-row class="row" v-else>
          <UserLandItem v-for="(item, i) in userLands" :key="i" :item="item" @fetchLands="fetchLands"/>
        </v-row>
      </section>
    </section>
  </section>
</template>

<script>
import UserLandItem from "./UserLandItem.vue";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      userLands: [],
      isLoading: false,
    };
  },
  components: {
    UserLandItem,
  },

  created() {
    this.fetchUserLands();
  },
  methods: {
    ...mapActions(["getUserLands"]),
    fetchUserLands() {
      this.isLoading = true;
      this.getUserLands().then((result) => {
        this.userLands = result.result;
        console.log("user lands:", this.userLands);
        this.isLoading = false;
      });
    },
    fetchLands(){
      this.fetchUserLands();
    }
  },
};
</script>

<style lang="scss" scoped>
.land {
  .content {
  }
}
.transfar-card {
  .card-title {
    background: grey !important;
  }
}
</style>
